import { graphql, PageProps } from 'gatsby'
import React from 'react'
import CommonH2 from '../../components/atoms/commonH2'
import SEO from '../../components/atoms/seo'
import TopBeginnerBtn from '../../components/molecules/topBeginnerBtn'
import TopFaqCard from '../../components/molecules/topFaqCard'
import TopMenuArea from '../../components/molecules/topMenuArea'
import Layout from '../../components/organisms/layout'
import { CategoryKind } from '../../types/CategoryKind'
import ManualBtn from '../../components/molecules/manualBtn'

type IProps = PageProps<GatsbyTypes.HelpIndexPageQuery>

/**
 * TOP
 * @constructor
 */
const HelpIndexPage = ({ location }: IProps) => (
  // GraphQLで取得したデータを保持

  <Layout location={location} categoryKind={CategoryKind.INDEX}>
    <SEO title="TOP" />
    <CommonH2 className="mb-020" label="よくあるご質問" />
    <TopFaqCard />
    <TopBeginnerBtn />
    <TopMenuArea />
    <ManualBtn />
  </Layout>
)

export default HelpIndexPage

export const pageQuery = graphql`
  query HelpIndexPage {
    awsGraphQL {
      listHelpCategories {
        items {
          helpCategoryId
          sortNo
          title
          slug
          categoryKind
          _deleted
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
