import styled from '@emotion/styled'
import React from 'react'

const Wrapper = styled.div`
  padding: 0;
  .cls-1{fill:#fff;}
  .cls-2{fill:#666;}
`

/**
 * SVGアイコンコンポーネント
 * ロゴ
 */
const SettingSvg = (): JSX.Element => (
  <Wrapper>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
      <path 
        className="cls-1" 
        d="M32.2,7.23C27.2,2.23,19.95,.86,13.71,3.11l11.3,11.3h0s-10.59,10.6-10.59,10.6h0S3.11,13.71,3.11,13.71c-2.25,6.24-.88,13.49,4.12,18.48,4.84,4.84,11.8,6.28,17.9,4.32l34.58,37.88c.15,.17,.3,.33,.46,.49,4.06,4.06,10.65,4.06,14.72,0,4.06-4.06,4.06-10.65,0-14.72-.16-.16-.32-.31-.49-.46L36.51,25.13c1.96-6.1,.53-13.06-4.32-17.9Zm38.11,63.08c-1.53,1.53-4.02,1.53-5.55,0-1.53-1.53-1.53-4.02,0-5.55s4.02-1.53,5.55,0c1.53,1.53,1.53,4.02,0,5.55Z"
      />
      <path 
        className="cls-2" 
        d="M67.53,79.9c-3.17,0-6.34-1.21-8.75-3.62-.19-.19-.37-.39-.54-.58L24.5,38.75c-6.69,1.69-13.74-.23-18.66-5.16C.48,28.24-1.32,20.17,1.25,13.04c.23-.64,.77-1.11,1.43-1.26,.66-.15,1.35,.05,1.83,.53l9.91,9.91,7.81-7.81L12.32,4.5c-.48-.48-.68-1.17-.53-1.83,.15-.66,.62-1.2,1.26-1.43,7.13-2.57,15.19-.77,20.55,4.58h0c4.92,4.92,6.84,11.97,5.15,18.66l36.98,33.76c.17,.15,.37,.33,.56,.52,4.83,4.83,4.83,12.68,0,17.51-2.41,2.41-5.58,3.62-8.75,3.62ZM25.13,34.54c.54,0,1.08,.23,1.46,.64l34.58,37.88c.14,.16,.27,.3,.4,.43,1.59,1.59,3.71,2.47,5.96,2.47s4.37-.88,5.96-2.47c3.29-3.29,3.29-8.64,0-11.93-.13-.13-.27-.25-.4-.37L35.18,26.59c-.57-.52-.78-1.33-.55-2.06,1.81-5.63,.34-11.73-3.83-15.9-3.49-3.49-8.43-5.09-13.22-4.44l8.83,8.83c.38,.38,.59,.89,.58,1.42s-.23,1.04-.62,1.41l-10.56,10.56c-.74,.74-2.06,.75-2.8,0L4.18,17.58c-.65,4.79,.95,9.73,4.44,13.22,4.18,4.18,10.27,5.65,15.9,3.83,.2-.06,.4-.09,.6-.09Zm42.4,38.88c-1.51,0-3.02-.57-4.17-1.72-2.3-2.3-2.3-6.04,0-8.34,2.3-2.3,6.04-2.3,8.34,0,1.11,1.11,1.73,2.59,1.73,4.17s-.61,3.06-1.73,4.17h0c-1.15,1.15-2.66,1.72-4.17,1.72Zm2.77-3.12h0Zm-2.77-4.72c-.5,0-1,.19-1.38,.57-.76,.76-.76,2,0,2.76,.76,.76,2,.76,2.76,0,.37-.37,.57-.86,.57-1.38s-.2-1.01-.57-1.38c-.38-.38-.88-.57-1.38-.57Z"
      />
    </svg>
  </Wrapper >
)
export default SettingSvg
