import styled from '@emotion/styled'
import React from 'react'

const Wrapper = styled.div`
  padding: 0;
  .cls-1 {
    fill: #fff;
  }
  .cls-2 {
    fill: #4d4d4d;
  }
  .cls-3 {
    fill: #19ba9b;
  }
  .cls-4 {
    fill: #fcee21;
  }
`

/**
 * SVGアイコンコンポーネント
 * TOPページはじめてガイド用ロゴ
 */
const BeginnerSvg = (): JSX.Element => (
  <Wrapper>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
      <path
        className="cls-1"
        d="M40,24.09L21.71,5.8c-1.87-1.87-5.06-.54-5.06,2.1V51.73c0,.79,.31,1.54,.87,2.1l20.39,20.39c1.16,1.16,3.04,1.16,4.19,0l20.39-20.39c.56-.56,.87-1.31,.87-2.1V7.89c0-2.64-3.19-3.96-5.06-2.1l-18.29,18.29Z"
      />
      <path
        className="cls-2"
        d="M60.37,4.92c1.52,0,2.99,1.18,2.99,2.97V51.73c0,.79-.31,1.54-.87,2.1l-20.39,20.39c-.58,.58-1.34,.87-2.1,.87s-1.52-.29-2.1-.87l-20.39-20.39c-.56-.56-.87-1.31-.87-2.1V7.89c0-1.79,1.46-2.97,2.99-2.97,.73,0,1.47,.27,2.08,.87l18.29,18.29L58.29,5.8c.6-.6,1.35-.87,2.08-.87M60.37,0C58.28,0,56.31,.82,54.81,2.32l-14.81,14.81L25.19,2.32C23.69,.82,21.72,0,19.63,0c-2.02,0-3.95,.76-5.42,2.14-1.6,1.5-2.48,3.54-2.48,5.75V51.73c0,2.11,.82,4.09,2.31,5.58l20.39,20.39c1.49,1.49,3.47,2.31,5.58,2.31s4.09-.82,5.58-2.31l20.39-20.39c1.49-1.49,2.31-3.47,2.31-5.58V7.89C68.27,3.54,64.73,0,60.37,0h0Z"
      />
      <path
        className="cls-3"
        d="M58.29,5.8l-18.29,18.29v50.99c.76,0,1.52-.29,2.1-.87l20.39-20.39c.56-.56,.87-1.31,.87-2.1V7.89c0-2.64-3.19-3.96-5.06-2.1Z"
      />
      <path
        className="cls-4"
        d="M21.71,5.8c-1.87-1.87-5.06-.54-5.06,2.1V51.73c0,.79,.31,1.54,.87,2.1l20.39,20.39c.58,.58,1.34,.87,2.1,.87V24.09L21.71,5.8Z"
      />
    </svg>
  </Wrapper>
)
export default BeginnerSvg
