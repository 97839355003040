import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ManageSvg from '../atoms/manageSvg'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.div`
  width: calc(100% / 3 - 10px);
  background: #fff;
  border-radius: 10px;
  border: #cccccc solid 3px;
  padding: 50px 25px 20px 25px;
  margin-bottom: 40px;
  position: relative;
  .svg {
    position: absolute;
    top: -40px;
    left: calc(50% - 35px);
    height: 70px;
    width: 70px;
  }
  a {
    h2 {
      font-size: 22px;
      margin-bottom: 20px;
      text-align: center;
      color: #414141;
    }
    &:hover {
      h2 {
        color: #00b5a6;
      }
    }
  }
  .list {
    display: flex;
    margin-left: 15px;
  }
  .category-list {
    li {
      margin: 0 0 10px 5px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      a {
        position: relative;
        margin-left: 15px;
        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 3px solid transparent;
          border-bottom: 3px solid transparent;
        }
      }
    }
  }
  .allCategory {
    position: absolute;
    bottom: 20px;
    right: 25px;
  }
  ${BreakPoints.large} {
    width: 90%;
    margin: 0 auto 60px auto;
    .category-list {
      li {
        width: 100%;
      }
    }
    .allCategory {
      position: static;
      text-align: right;
    }
  }
`

/**
 * TOPよくあるご質問カード
 * @constructor
 */
const TopManageCard = () => {
  const routeParam = useRouteParam('/help/manage')
  const routeParam2 = useRouteParam('/help/management')
  const routeParam3 = useRouteParam('/help/approval')
  const routeParam4 = useRouteParam('/help/manage')
  return (
    <Wrapper>
      <a href={routeParam}>
        <div className="svg">
          <ManageSvg />
        </div>
        <h2>管理職機能</h2>
      </a>
      <div className="list">
        <ul className="category-list">
          <li>
            <a href={routeParam2}>従業員の勤怠データ確認</a>
          </li>
          <li>
            <a href={routeParam3}>承認作業</a>
          </li>
        </ul>
      </div>
      <div className="allCategory">
        <a href={routeParam4}>管理職機能のヘルプ一覧へ</a>
      </div>
    </Wrapper>
  )
}

export default TopManageCard
