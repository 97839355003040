import styled from '@emotion/styled'
import React from 'react'

const Wrapper = styled.div`
  padding: 0;
  .cls-1 {
    fill: #fff;
  }
  .cls-2 {
    fill: #19ba9b;
  }
  .cls-3 {
    fill: #d5f3ee;
  }
  .cls-4 {
    fill: #666;
  }
`

/**
 * SVGアイコンコンポーネント
 * TOPページ管理職機能用ロゴ
 */
const ManageSvg = (): JSX.Element => (
  <Wrapper>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
      <path
        className="cls-1"
        d="M53.79,0H19.96C17.98,0,16.36,1.61,16.36,3.6v63.36c0,1.99,1.61,3.6,3.6,3.6h51.21c1.99,0,3.6-1.61,3.6-3.6V20.99L53.79,0Z"
      />
      <path
        className="cls-4"
        d="M52.13,4l18.64,18.64v43.92H20.36V4h31.77M53.79,0H19.96C17.98,0,16.36,1.61,16.36,3.6v63.36c0,1.99,1.61,3.6,3.6,3.6h51.21c1.99,0,3.6-1.61,3.6-3.6V20.99L53.79,0h0Z"
      />
      <path
        className="cls-1"
        d="M53.79,9v8.38c0,1.99,1.61,3.6,3.6,3.6h17.39L53.79,0V9Z"
      />
      <path
        className="cls-4"
        d="M57.79,9.66l7.33,7.33h-7.33v-7.33M53.79,0V17.39c0,1.99,1.61,3.6,3.6,3.6h17.39L53.79,0h0Z"
      />
      <path
        className="cls-1"
        d="M48.22,4.72H14.39c-1.99,0-3.6,1.61-3.6,3.6v63.36c0,1.99,1.61,3.6,3.6,3.6h51.21c1.99,0,3.6-1.61,3.6-3.6V25.71L48.22,4.72Z"
      />
      <path
        className="cls-4"
        d="M46.56,8.72l18.64,18.64v43.92H14.79V8.72h31.77m1.66-4H14.39c-1.99,0-3.6,1.61-3.6,3.6v63.36c0,1.99,1.61,3.6,3.6,3.6h51.21c1.99,0,3.6-1.61,3.6-3.6V25.71L48.22,4.72h0Z"
      />
      <path
        className="cls-1"
        d="M48.22,13.72v8.38c0,1.99,1.61,3.6,3.6,3.6h17.39L48.22,4.72V13.72Z"
      />
      <path
        className="cls-4"
        d="M52.22,14.38l7.33,7.33h-7.33v-7.33m-4-9.66V22.11c0,1.99,1.61,3.6,3.6,3.6h17.39L48.22,4.72h0Z"
      />
      <path
        className="cls-1"
        d="M42.65,9.44H8.82c-1.99,0-3.6,1.61-3.6,3.6v63.36c0,1.99,1.61,3.6,3.6,3.6H60.04c1.99,0,3.6-1.61,3.6-3.6V30.43L42.65,9.44Z"
      />
      <path
        className="cls-4"
        d="M40.99,13.44l18.64,18.64v43.92H9.22V13.44h31.77m1.66-4H8.82c-1.99,0-3.6,1.61-3.6,3.6v63.36c0,1.99,1.61,3.6,3.6,3.6H60.04c1.99,0,3.6-1.61,3.6-3.6V30.43L42.65,9.44h0Z"
      />
      <rect className="cls-3" x="14.52" y="16.99" width="30.11" height="7.1" />
      <rect className="cls-3" x="14.52" y="65.36" width="39.83" height="7.1" />
      <rect className="cls-3" x="14.52" y="53.26" width="39.83" height="7.1" />
      <rect className="cls-3" x="14.52" y="41.17" width="39.83" height="7.1" />
      <rect className="cls-3" x="14.52" y="29.08" width="39.83" height="7.1" />
      <path
        className="cls-1"
        d="M42.65,18.45v8.38c0,1.99,1.61,3.6,3.6,3.6h17.39L42.65,9.44v9Z"
      />
      <path
        className="cls-4"
        d="M46.65,19.1l7.33,7.33h-7.33v-7.33m-4-9.66V26.83c0,1.99,1.61,3.6,3.6,3.6h17.39L42.65,9.44h0Z"
      />
      <path
        className="cls-2"
        d="M50.75,36.19l-18.33,18.33-8.01-8.01c-.75-.75-1.96-.75-2.71,0l-1.94,1.94c-.75,.75-.75,1.96,0,2.71l9.37,9.37,1.94,1.94,.05,.05c.75,.75,1.96,.75,2.71,0l1.94-1.94c.13-.13,.22-.27,.3-.41l19.33-19.33c.75-.75,.75-1.96,0-2.71l-1.94-1.94c-.75-.75-1.96-.75-2.71,0Z"
      />
      <path
        className="cls-2"
        d="M46.74,52.48c-.72,6.3-5.73,11.29-12.03,11.99-.44,.3-.92,.53-1.47,.66h-1.38c-.69-.18-1.27-.52-1.79-.95-4.97-1.18-8.89-5.07-10.09-10.03-.32-.31-.63-.63-.95-.94-.84-.83-1.52-1.71-1.72-2.91v-.83c.2-1.23,.89-2.16,1.8-2.97,.38-.34,.72-.71,1.08-1.07,.29-.29,.59-.52,.89-.72,2.26-4.41,6.84-7.43,12.13-7.43,3.81,0,7.26,1.57,9.73,4.09,1.57-1.57,3.15-3.15,4.72-4.73-3.68-3.73-8.8-6.04-14.45-6.04-11.22,0-20.31,9.09-20.31,20.31s9.09,20.31,20.31,20.31,20.31-9.09,20.31-20.31c0-1.6-.19-3.16-.54-4.65-2.08,2.07-4.15,4.14-6.23,6.21Z"
      />
    </svg>
  </Wrapper>
)
export default ManageSvg
