import styled from '@emotion/styled'
import React from 'react'
import { Link } from 'gatsby'
import BreakPoints from '../../styles/breakPoints'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 25px;
  margin-bottom: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  .faq-list {
    display: flex;
    justify-content: space-around;
  }

  .category-list {
    li {
      margin: 0 0 10px 5px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      a {
        position: relative;
        margin-left: 15px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 3px solid transparent;
          border-bottom: 3px solid transparent;
        }
      }
    }
  }

  .allFaq {
    text-align: right;
  }

  @media screen and (max-width: 880px) {
    position: relative;
    display: flex;
    justify-content: center;
    .faq-list {
      flex-direction: column;
      margin-bottom: 25px;
    }

    .allFaq {
      position: absolute;
      bottom: 25px;
      right: 25px;
    }
  }

  ${BreakPoints.large} {
    width: 90vw;
    margin: 0 auto 20px auto;
  }

  ${BreakPoints.medium} {
    .faq-list {
      width: 80vw;
    }
  }
`

/**
 * TOPよくあるご質問カード
 * @constructor
 */
const TopFaqCard = () => {
  const routePram = useRouteParam('/help/article/10140')
  const routePram2 = useRouteParam('/help/article/10155')
  const routePram3 = useRouteParam('/help/article/10156')
  const routePram4 = useRouteParam('/help/article/10158')
  const routePram5 = useRouteParam('/help/article/10178')
  const routePram6 = useRouteParam('/help/article/10194')
  const routePram7 = useRouteParam('/help/article/10193')
  const routePram8 = useRouteParam('/help/faq')
  return (
    <Wrapper>
      <div className="faq-list">
        <ul className="category-list">
          <li>
            <Link to={routePram}>打刻を忘れてしまいました</Link>
          </li>
          <li>
            <Link to={routePram2}>
              ログインができません （Googleサインインを利用の場合）
            </Link>
          </li>
          <li>
            <Link to={routePram3}>
              ログインができません （メールアドレスを利用の場合）
            </Link>
          </li>
          <li>
            <Link to={routePram4}>シフト変更をしたいがエラーになります</Link>
          </li>
        </ul>
        <ul className="category-list">
          <li>
            <Link to={routePram5}>
              パスワードを忘れてしまったため、登録し直したい
            </Link>
          </li>
          <li>
            <Link to={routePram6}>アクセス制限がかかってしまいました</Link>
          </li>
          <li>
            <Link to={routePram7}>請求が来るのはいつですか？</Link>
          </li>
        </ul>
      </div>
      <div className="allFaq">
        <Link to={routePram8}>全ての質問を表示</Link>
      </div>
    </Wrapper>
  )
}

export default TopFaqCard
