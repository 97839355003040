import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import BeginnerSvg from '../atoms/BeginnerSvg'
import MovieSvg from '../atoms/MovieSvg'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.div`
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  gap: 20px;

  a {
    flex: 1;
    background: #ffffff;
    border: #cccccc solid 3px;
    border-radius: 10px;
    padding: 30px 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    &:hover {
      box-shadow: 2px 2px 5px #444;
      text-decoration: underline solid transparent;
    }

    .beginner {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .beginner h2 {
      margin-left: 10px;
      font-size: 26px;
      color: #414141;
    }

    .txt {
      text-align: center;
      margin-top: 10px;
      line-height: normal;
    }

    svg {
      height: 40px;
      width: 40px;
    }
  }

  ${BreakPoints.large} {
    width: 90%;
    flex-direction: column;
    margin: 0 auto 60px auto;
  }
`

/**
 * はじめにボタン
 * @constructor
 */
const TopBeginnerBtn = () => {
  const routeParam = useRouteParam('/help/first')
  const routeParam2 = useRouteParam('/help/movie')
  return (
    <Wrapper>
      <a href={routeParam}>
        <div className="beginner">
          <BeginnerSvg />
          <h2>はじめてガイド</h2>
        </div>
        <div className="txt">
          <p>
            キンクラを初めてご利用いただく際に読んでいただきたい
            <br />
            基本操作をご案内します
          </p>
        </div>
      </a>
      <a href={routeParam2}>
        <div className="beginner">
          <MovieSvg />
          <h2>動画マニュアル</h2>
        </div>
        <div className="txt">
          <p>
            実際の画面を表示しながら
            <br />
            操作方法や使い方を動画でまとめました
          </p>
        </div>
      </a>
    </Wrapper>
  )
}

export default TopBeginnerBtn
