import styled from '@emotion/styled'
import React from 'react'

const Wrapper = styled.div`
  padding: 0;
  .cls-1 {
    fill: #fff;
  }
  .cls-2 {
    fill: #4d4d4d;
  }
  .cls-3 {
    fill: #19ba9b;
  }
  .cls-4 {
    fill: #fcee21;
  }
`

/**
 * SVGアイコンコンポーネント
 * TOPページ動画マニュアル用アイコン
 */
const MovieSvg = (): JSX.Element => (
  <Wrapper>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.4 41.4">
      <g
        id="グループ_5484"
        data-name="グループ 5484"
        transform="translate(-1.546 -2.377)"
      >
        <rect
          id="長方形_23187"
          data-name="長方形 23187"
          width="49"
          height="39"
          rx="4"
          transform="translate(2.746 3.577)"
          fill="#f72828"
          stroke="#4d4d4d"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.4"
        />
        <path
          id="パス_1796"
          data-name="パス 1796"
          d="M26.7,19.022,10,9V29.044Z"
          transform="translate(10.186 4.321)"
          fill="#fff"
        />
      </g>
    </svg>
  </Wrapper>
)
export default MovieSvg
