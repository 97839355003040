import styled from '@emotion/styled'
import React from 'react'

const Wrapper = styled.div`
  padding: 0;
  .cls-1{fill:#fff;}
  .cls-2{fill:#d5f3ee;}
  .cls-3{fill:#666;}
`

/**
 * SVGアイコンコンポーネント
 * ロゴ
 */
const GuideSvg = (): JSX.Element => (
  <Wrapper>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
      <path 
        className="cls-1" 
        d="M80,13.63c-13.11-3.18-26.89-3.18-40,0h0C26.89,10.45,13.11,10.45,0,13.63h0v55.13H0c13.11-3.18,26.89-3.18,40,0h0c13.11-3.18,26.89-3.18,40,0h0V13.63h0Z"
      />
      <path 
        className="cls-3" 
        d="M60,15.25c5.38,0,10.75,.53,16,1.59V63.82c-5.27-.96-10.63-1.45-16-1.45-6.74,0-13.46,.76-20,2.27-6.54-1.51-13.26-2.27-20-2.27-5.37,0-10.73,.49-16,1.45V16.84c5.25-1.06,10.62-1.59,16-1.59,6.43,0,12.84,.76,19.06,2.27,.31,.08,.63,.11,.94,.11s.63-.04,.94-.11c6.22-1.51,12.63-2.27,19.06-2.27m0-4c-6.72,0-13.45,.79-20,2.38-6.55-1.59-13.28-2.38-20-2.38S6.55,12.04,0,13.63v55.13c6.55-1.59,13.28-2.38,20-2.38s13.45,.79,20,2.38c6.55-1.59,13.28-2.38,20-2.38s13.45,.79,20,2.38V13.63c-6.55-1.59-13.28-2.38-20-2.38h0Z"
      />
      <rect 
        className="cls-3" 
        x="38" 
        y="14.78" 
        width="4" 
        height="52.62"
      />
      <rect 
        className="cls-2" 
        x="7.79" 
        y="23.52" 
        width="26.86" 
        height="3.64" transform="translate(42.43 50.68) rotate(180)"
      />
      <rect 
        className="cls-2" 
        x="7.79" 
        y="33.29" 
        width="26.86" 
        height="3.64" transform="translate(42.43 70.23) rotate(180)"
      />
      <rect 
        className="cls-2" 
        x="7.79" 
        y="43.06" 
        width="26.86" 
        height="3.64" transform="translate(42.43 89.77) rotate(180)"
      />
      <rect 
        className="cls-2" 
        x="7.79" 
        y="52.84" 
        width="26.86" 
        height="3.64" transform="translate(42.43 109.32) rotate(180)"
      />
      <rect 
        className="cls-2" 
        x="45.93" 
        y="23.52" 
        width="26.86" 
        height="3.64" transform="translate(118.72 50.68) rotate(180)"
      />
      <rect 
        className="cls-2" 
        x="45.93" 
        y="33.29" 
        width="26.86" 
        height="3.64" transform="translate(118.72 70.23) rotate(180)"
      />
      <rect 
        className="cls-2" 
        x="45.93" 
        y="43.06" 
        width="26.86" 
        height="3.64" transform="translate(118.72 89.77) rotate(180)"
      />
      <rect 
        className="cls-2" 
        x="45.93" 
        y="52.84" 
        width="26.86" 
        height="3.64" transform="translate(118.72 109.32) rotate(180)"
      />
    </svg>
  </Wrapper >
)
export default GuideSvg
