import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import SettingSvg from '../atoms/settingSvg'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.div`
  width: calc(100% / 3 - 10px);
  background: #fff;
  border-radius: 10px;
  border: #cccccc solid 3px;
  padding: 50px 25px 20px 25px;
  margin-bottom: 40px;
  position: relative;
  .svg {
    position: absolute;
    top: -40px;
    left: calc(50% - 35px);
    height: 70px;
    width: 70px;
  }
  a {
    h2 {
      font-size: 22px;
      margin-bottom: 20px;
      text-align: center;
      color: #414141;
    }
    &:hover {
      h2 {
        color: #00b5a6;
      }
    }
  }
  .list {
    display: flex;
    margin-left: 15px;
  }
  .category-list {
    li {
      margin: 0 0 10px 5px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      a {
        position: relative;
        margin-left: 15px;
        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 3px solid transparent;
          border-bottom: 3px solid transparent;
        }
      }
    }
  }
  .allCategory {
    text-align: right;
    margin: 15px 0 0;
  }
  ${BreakPoints.large} {
    width: 90%;
    margin: 0 auto;
    .category-list {
      li {
        width: 100%;
      }
    }
  }
`

/**
 * TOPよくあるご質問カード
 * @constructor
 */
const TopSettingCard = () => {
  const routePram = useRouteParam('/help/setting')
  const routePram2 = useRouteParam('/help/corporate')
  const routePram3 = useRouteParam('/help/employee')
  const routePram4 = useRouteParam('/help/Holiday')
  const routePram5 = useRouteParam('/help/infomation')
  const routePram6 = useRouteParam('/help/closing')
  const routePram7 = useRouteParam('/help/setting')
  return (
    <Wrapper>
      <a href={routePram}>
        <div className="svg">
          <SettingSvg />
        </div>
        <h2>各種設定</h2>
      </a>
      <div className="list">
        <ul className="category-list">
          <li>
            <a href={routePram2}>企業情報の設定</a>
          </li>
          <li>
            <a href={routePram3}>従業員の追加や管理</a>
          </li>
          <li>
            <a href={routePram4}>休暇の設定管理をする</a>
          </li>
          <li>
            <a href={routePram5}>お知らせ機能の利用・管理</a>
          </li>
          <li>
            <a href={routePram6}>締め関連の処理</a>
          </li>
        </ul>
      </div>
      <div className="allCategory">
        <a href={routePram7}>各種設定のヘルプ一覧へ</a>
      </div>
    </Wrapper>
  )
}

export default TopSettingCard
